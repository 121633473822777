$green100: #a8ff35;
$green200: #87d322;
$green300: #8cc83a;
$black100: #000000;
$black200: #2b2b2b;
$gray100: #f1f1f1;
$gray200: #f5f5f5;
$gray300: #adadad;
$gray400: #8b8b8b;
$white: #ffffff;

$primary: $green100;
$dark: $black100;
$light: $white;
$secondary: $gray400;
$info: $gray200;
@font-face {
  font-family: 'Effra-Light'; /*Can be any text*/
  src: local('Effra-Light'),
    url('./TrialStaticFonts/Effra\ Lt.ttf') format('truetype');
}

@font-face {
  font-family: 'Effra-Regular'; /*Can be any text*/
  src: local('Effra-Regular'),
    url('./TrialStaticFonts/Effra\ Rg.ttf') format('truetype');
}
@font-face {
  font-family: 'Effra-Black'; /*Can be any text*/
  src: local('Effra-Regular'),
    url('./TrialStaticFonts/Effra\ Blk.ttf') format('truetype');
}
@font-face {
  font-family: 'Effra-Bold'; /*Can be any text*/
  src: local('Effra-Regular'),
    url('./TrialStaticFonts/Effra\ Bold.ttf') format('truetype');
}
@import '../node_modules/bootstrap/scss/bootstrap';

html {
  scroll-behavior: smooth;
}
:root {
  --bs-body-font-family: 'Effra-Bold';
}

.height {
  min-height: 100vh;
}
.header-font {
  font-family: 'Effra-Black' !important;
}
.text-heading {
  max-width: 650px;
  margin: auto;
}
.company-heading {
  max-width: 1200px;
  font-family: 'Effra-Bold';
  margin: 0 auto;
}
.company-heading2 {
  font-family: 'Effra-Bold';
}
.hero-images {
  @include media-breakpoint-up(xxl) {
    padding: 2rem 0;
  }
}
.company-logo {
  padding: 2rem 1rem;
  background: $info;
  margin: 0.5rem auto;
  max-width: 300px;
  min-width: 150px;
}
.platform-img {
  padding: 1rem;
  background: $light;
  max-width: 200px;
  min-width: 80px;
  margin: 0 auto;
}
.li-item-wrapper1 {
  margin-bottom: 12rem;
}
.li-item-wrapper2 {
  margin-bottom: 9rem;
}
.li-item-wrapper3 {
  margin-bottom: 3rem;
}
.payment-element {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.li-item {
  list-style: none;
  background: url(img/tick.svg) no-repeat 2px 8px;
  padding-left: 20px;
  text-align: left;
}
.join-trending {
  color: $green200;
}

.hero-image-wrapper {
  margin: 0 auto;
  max-width: 355px;
}

.hero-image-wrapper:nth-child(1) {
  transform: rotate(-3deg) translateY(10px);

  @include media-breakpoint-up(xxl) {
    transform: rotate(-3deg) translate(120px, -40px);
    z-index: 1;
  }
}
.hero-image-wrapper:nth-child(2) {
  left: 0;
  bottom: -125px;
  transform: rotate(3deg);
}
.hero-image-wrapper:nth-child(3) {
  left: 0;
  bottom: -225px;
  transform: rotate(-3deg) translateY(-10px);

  @include media-breakpoint-up(xxl) {
    transform: rotate(-3deg) translate(-100px, -50px);
    z-index: 2;
  }
}
.hero-image-wrapper:nth-child(4) {
  left: 0;
  bottom: -325px;
  transform: rotate(3deg) translateY(-10px);
  @include media-breakpoint-up(xxl) {
    transform: rotate(-3deg) translate(-170px, -30px);
    z-index: 1;
  }
}

.open-image-wrapper {
  max-width: 600px;
  margin: 0 auto;
}
